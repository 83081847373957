.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background-color: var(--navy-800);
}

.login-sp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.header-sp {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background-color: var(--accent-color);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 80px;
}

.header-content-sp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 80px;
  width: 100%;
  background-color: var(--accent-color);
}

.header-links {
  display: flex;
  gap: 80px;
  font-family: 'Geist', sans-serif;
}

.logo {
  cursor: pointer;
  width: 150px;
}

.header-link {
  font-size: 18px;
  padding: 16px;
  font-weight: 500;
  line-height: 23.4px;
  font-family: 'Geist', sans-serif;
  color: var(--grey-300);
}

.header-link:hover{
  color: var(--grey-100);
}

.header-link-sp {
  font-size: 18px;
  padding: 16px;
  font-weight: 500;
  line-height: 23.4px;
  font-family: 'Geist', sans-serif;
  color: var(--sp-000) !important;

}

.header-link-sp:hover{
  color: var(--hover-color) !important
}

.header-mobile-link {
  font-size: 20px;
  font-family: 'Geist', sans-serif;
  font-weight: medium;
  line-height: 26px;
  height: 14px;
  color: var(--grey-300);
}

.header-mobile-link-sp {
  font-size: 20px;
  font-family: 'Geist', sans-serif;
  font-weight: medium;
  line-height: 26px;
  height: 14px;
  color: var(--grey-300);
}

.header-mobile-link:hover {
  color: var(--grey-100);
}

.web-btn{
  width: 130px;
}

.first-section{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 48px;
}

.login{
  margin-top: 32px;
  margin-bottom: 48px;
}

.third-section{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-top: 34px;
  margin-bottom: 74px;
  height: 81px;
}

.third-section-sp{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-top: 34px;
  margin-bottom: 48px;
  min-height: 81px;
}

.header-mobile-link-small {
  font-size: 16px;
  font-weight: medium;
  font-family: 'Geist', sans-serif;
  line-height: 20.8px;
  height: 11px;
  color: var(--grey-300);
}

.line {
  border-top: 1px solid var(--blue-300);
  width: 95%;
  height: 2px;
  margin-left: 10px;
  margin-right: 10px;
}

.mobile-button {
  font-family: 'Geist', sans-serif;
  font-weight: 500;
  width: 95%;
  margin-left: 10px;
  margin-right: 10px;
  margin-left: 11px;
  margin-right: 11px;
  margin-bottom: 48px;
}

.header-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  height: 102px;
  font-family: 'Geist', sans-serif;
  font-weight: 500;
}

.header-actions-sp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  font-family: 'Geist', sans-serif;
  font-weight: 500;
}



.burger-menu {
  cursor: pointer;
}

.tham {
  position: relative;
  width: 18px;
  height: 18px;
}

.tham-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tham-inner {
  width: 100%;
  height: 4px;
  background-color: var(--burger-menu);
  border-radius: 2px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}


.tham-active .tham-inner:nth-child(1) {
  transform: rotate(45deg) translateY(10px);
}

.tham-active .tham-inner:nth-child(2) {
  opacity: 0;
}

.tham-active .tham-inner:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px);
}

.socials-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin-top: 24px;
  padding: 0;
}

.socials-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--grey-300);
  margin-bottom: 10px;
}

.socials-icons {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 7px;
}

.social-icon {
  width: 32px;
  height: 32px;
  fill: var(--grey-300);
  transition: fill 0.3s ease;
}

.social-icon:hover {
  fill: var(--primary-color);
}

@media (max-width: 768px) {
  .header-content {
    gap: 20px;
  }

  .header-actions {
    display: none;
  }

  .header-actions-sp {
    display: none;
  }

  .mobile-menu {
    display: flex;
    background-color: var(--navy-600);
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding-top: 48px;
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 10;
    gap: 40px;
  }

  .mobile-links {
    display: flex;
    align-items: start;
    flex-direction: column;

  }

  .mobile-menu a {
    color: var(--grey-300);
    text-decoration: none;
    padding: 10px;

  }


  .burger-menu {
    display: block;
    gap: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }


  .tham {
    position: relative;
    width: 24px;
    display: flex;
    justify-content: centers;

  }

  .tham-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    padding: 0 24px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {

  .header {
    padding: 0 24px;
  }

  .header-content {
    gap: 20px;
  }

  .header-actions {
    display: none;
  }

  .mobile-menu {
    display: flex;
    background-color: var(--navy-600);
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding-top: 48px;
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 10;
    gap: 40px;
  }

  .mobile-links {
    display: flex;
    align-items: start;
    flex-direction: column;

  }

  .mobile-menu a {
    color: var(--grey-300);
    text-decoration: none;
    padding: 10px;

  }


  .burger-menu {
    display: block;
    gap: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .tham {
    position: relative;
    width: 24px;
    display: flex;
    justify-content: centers;

  }

  .tham-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
 

  
}

@media (max-width: 1024px) {
  .header-content {
    gap: 40px;
  }

  .header-links {
    gap: 40px;
  }

  .header-actions {
    display: flex;
    gap: 20px;
  }

  .header-actions-sp {
    display: flex;
    gap: 20px;
  }

  .global-button {
    font-size: 14px;
    width: auto;
  }
}