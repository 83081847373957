.footer-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.footer {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 84px;
    bottom: 0;
    padding: 40px;
    padding-bottom: 0;
}

.footer-links-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 74px;
    height: 81px;
}

.footer-links-section-sp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 74px;
    min-height: 81px;
}

.footer-links {
    font-size: 18px;
    font-weight: medium;
    font-family: 'Geist', sans-serif;
    line-height: 23.4px;
    color: var(--grey-300);
}

.socials-section-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 0;
}

.footer-line {
    border-top: 1px solid var(--blue-300);
    height: 2px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
}

.footer-mobile-link-small {
    font-size: 16px;
    font-weight: medium;
    font-family: 'Geist', sans-serif;
    line-height: 20.8px;
    height: 11px;
    color: var(--grey-650);
}

.footer-mobile-link-small-title {
    font-size: 16px;
    font-weight: medium;
    font-family: 'Geist', sans-serif;
    line-height: 20.8px;
    height: 11px;
    color: var(--grey-650);
    cursor:default;
}

.footer-contact-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: var(--grey-300);
    margin: 0px;
}

.footer-contact-links-section {
    display: flex;
    flex-direction: column;
}

.footer-contact-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
}

.copyright {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    color: var(--copy-right);
    margin-left: 2.5rem;
    margin-bottom: 2.5rem;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        justify-content: flex-start;
        gap: 4.375rem;
        height: 100%;
        margin-bottom: 25px;
    }



    .footer-links-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 40px;
        height: 110px;
    }

    .footer-links-section-sp {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 0;
        height: 110px;
    }

    .footer-line {
        margin-bottom: 30px;
    }

    .footer-links-mobile {
        font-size: 14px;
    }

    .copyright {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.8px;
        color: var(--copy-right);
        margin: 0 2rem;
        margin-left: 2.5rem;
        margin-bottom: 2rem;
    }

}